.sub-header-callout {
    display: block;
    position: relative;
    text-decoration: none;
    color: $c-white;
    text-align: center;
    width: 100%;
    margin-top: 20px;

    @include breakpoint(nav) {
        margin-top: 30px;
        margin-bottom: 40px;
        max-width: 500px;
    }
}

    .sub-header-callout__image {
        aspect-ratio: 4 / 2.5;
        overflow: hidden;
        filter: brightness(0.4);

        img {
            width: 100%;
            height: auto;
            transition: transform 500ms ease-in;

            .sub-header-callout:hover & {
                transform: scale(1.075);
            }
        }
    }

    .sub-header-callout__content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
    
        p {
            @include intro--small;
            
            margin-bottom: 20px;
            text-transform: none;
        }
    }
