button {
    all: unset;
}

.button {
    @include mediumText;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    color: var(--button-colour, $c-black);
    border: 2px solid var(--button-colour, $c-black);
    background-color: transparent;
    transition:
        background-color 100ms ease-in,
        color 100ms ease-in,
        border-color 100ms ease-in;
    padding: 0 30px;
    border-radius: 30px;
    text-decoration: none;
    box-sizing: border-box;

    a:hover &,
    a:active &,
    &:hover,
    &:active {
        background-color: var(--button-colour, $c-black);
        color: $c-white;
    }

    &::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 0px;
        height: 44px;
    }
}

.button--white {
    color: $c-white;
    border-color: $c-white;

    a:hover &,
    a:active &,
    &:hover,
    &:active {
        background-color: $c-white;
        color: $c-black;
    }
}

.button--adaptive {
    color: var(--header-content-colour);
    border-color: var(--header-content-colour);

    a:hover &,
    a:active &,
    &:hover,
    &:active {
        background-color: var(--header-content-colour);
        color: var(--header-content-inverse);
    }
}

.button--border {
    @include font(12px, 16px);

    --button-colour: #{$c-black};
    color: var(--button-colour);
    border: 0;
    position: relative;
    padding: 0;
    padding-bottom: 3px;
    text-transform: uppercase;
    transition: font-weight 250ms ease-in;
    letter-spacing: 1px;

    a:hover &,
    a:active &,
    &:hover,
    &:active {
        @include mediumText;

        background-color: transparent;
        color: var(--button-colour);
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: var(--button-colour);
        width: 25%;
        transition: width 250ms linear;
    }

    &:hover::after {
        width: 100%;
    }
}

.button--border::after {
    a:hover &,
    a:active & {
        width: 100%;
    }
}

.button--border-white {
    --button-colour: #{$c-white};
}

.button--no-transform {
    text-transform: none;
}

.button--full {
    width: 100%;
    padding-inline: 0;
}

.button--border-gold {
    --button-colour: #{$c-gold};
}
